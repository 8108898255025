import React from 'react'
import './header.css';
import brain from '../../assets/brain.svg';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import  { Fade } from 'react-awesome-reveal';
const Header = () => {
  const particlesInit = async (main) => {

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    container.canvas.element.style.position = "";
    container.canvas.element.style.zindex = "";
    container.canvas.element.style.height = "100% !important";
    container.canvas.element.style.width = "100% !important";
  };
  return (
    <div className='header'>
      <div className='header_background'>
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        background: {
          color: {
            value: "#fff",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: false,
          },
          modes: {
            push: {
              quantity: 2,
            },
            repulse: {
              distance: 120,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#1FDAF4",
          },
          links: {
            color: "#1FDAF4",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
      </div>
      <Fade >
      <div className='header_container'>
        <div className="header-content">
          <h1 className="gradient__text">NFT Development & Consulting Services</h1>
          <p>The best for our clients is not good enough! We offer an all-in-one solution in the field of NFT & Blockchain. We will be glad to help you in the realization of your NFT project in all sectors. We pave your way to success!</p>
          <a href='#contact'><button type ="button">Talk to our experts</button></a>
        </div>
        <div className='header-image'>
            <img src={brain} alt="w3minds-nft" />
        </div>
      </div>
      </Fade>
    </div>
  )
}

export default Header