import React,{useState} from 'react'
import ethereum from '../../assets/ethereum.svg';
import binance from '../../assets/binance.svg';
import bitcoin from '../../assets/bitcoin.svg';
import cardano from '../../assets/cardano.svg';
import polygon from '../../assets/polygon.svg';
import solana from '../../assets/solana.svg';
import  { Fade } from 'react-awesome-reveal';
import './blockchain.css'
const Blockchain = () => {
  const [chains, setchains] = useState([
    {
      open: true
    },
    {
      open: false
    },
    {
      open: false
    },
    {
      open: false
    },
    {
      open: false
    },
    {
      open: false
    },
    {
      open: false
    },
    {
      open: false
    }
  ]);
  const togglechain = index => {
    setchains(chains.map((chain, i) => {
      if (i === index) {
        chain.open = !chain.open
      } else {
        chain.open = false;
      }

      return chain;
    }))
  }
  return (
    <div className='blockchain'>
      <Fade>
            <div className='blockchain_header'>
                <h2>Experts in the field of blockchain</h2>
                <div></div>
                <p>
                We use the most secure and popular blockchains entirely according to customer requirements. We never cut corners on deliverables quality, so your NFT will be built on watertight security and robustness.</p>
            </div>
            <div className='chains'>
            <div  className={"chain " + (chains[0].open ? 'open' : '')} key={0} onClick={() => togglechain(0)} >
              <div className="chain-question">
                <img src={ethereum} alt="Ethereum" />
                Ethereum
              </div>
              <div className="chain-answer">
              w3minds blockchain development team is an expert in NFT development over the Ethereum standard for satisfying the requirement. Ethereum is the leading blockchain platform that gives a push to the NFT market with ERC-721 and ERC-1155 standards. The NFT development service by Also, the NFT trade over Ethereum could be P2P where service fees can be eliminated with no need for any platform
              </div>  
            </div>
            <div  className={"chain " + (chains[1].open ? 'open' : '')} key={1} onClick={() => togglechain(1)} >
              <div className="chain-question">
              <img src={solana} alt="Solana" />
                  Solana
              </div>
              <div className="chain-answer">
              Solana blockchain development by w3minds delivers a rapid transactional experience for different blockchain development. For accelerated and more active participation of validators, Solana NFT development services are the right fit. The platform is growing faster because of less gas fees, a highly composable marketplace, and smooth/high-speed transactions. The NFT development solutions at w3minds fulfill the market requirement and build a great scalable asset for the future. Our developers create NFT as a market-ready product that can be listed on the NFT platforms.
              </div>  
            </div>
            <div  className={"chain " + (chains[2].open ? 'open' : '')} key={2} onClick={() => togglechain(2)} >
              <div className="chain-question">
              <img src={binance} alt="Binance" />
              Binance
              </div>
              <div className="chain-answer">
              Our experienced team provides NFT token development services overall leading blockchain platforms. We at w3minds develop Binance Smart Chain keeping the most upbeat market standards at the highest priority. Binance network is perfectly built for high quality and optimized pricing. The NFT platform development with Binance is extremely interoperable. Binance leverages amazing benefits such as EVM compatibility, proof of Stake authority, and cross-chain transfers. Binance smart chain NFTs are compatible with the upcoming requirements.              </div>  
            </div>
            <div  className={"chain " + (chains[3].open ? 'open' : '')} key={3} onClick={() => togglechain(3)} >
              <div className="chain-question">
              <img src={cardano} alt="Cardano" />
              Cardano
              </div>
              <div className="chain-answer">
              Cardano is a popular decentralized blockchain technology with a higher output than its competitive Blockchains. The evidence-based blockchain supports customers and the requirements. The technology is considered the next-generation blockchain, where smart contracts competitively beat their counterparts in outperforming the advanced features. The Cardano blockchain sustainability is maintained by the incentive technology where the participant receives the reward.              </div>  
            </div>
            <div  className={"chain " + (chains[4].open ? 'open' : '')} key={4} onClick={() => togglechain(4)} >
              <div className="chain-question">
              <img src={polygon} alt="Polygon" />
              Polygon
              </div>
              <div className="chain-answer">
              Matic network originally known as the Polygon is the open-source blockchain protocol that offers amazing interchain scalability solutions. The blockchain is intentionally developed to bring scalability and adaptability along with security, interoperability, and liquidity. w3minds team is experienced in working with leading platforms that can function like a protocol and complete multichain network. Polygon is used for infrastructure development and scaling with Ethereum. It lowers the barrier for non-crypto users and facilitates social engagement between users and creators. 
              </div>  
            </div>
            <div  className={"chain " + (chains[5].open ? 'open' : '')} key={5} onClick={() => togglechain(5)} >
              <div className="chain-question">
              <img src={bitcoin} alt="Bitcoin" />
                  Bitcoin
              </div>
              <div className="chain-answer">
              Probably the best known currency cryptocurrency with the largest market cap, Bitcoin can also be advantageous for NFTs. Not much of a novelty as it might first seem, the platform allows deploying NFTs through its Colored Coins protocol.              </div>  
            </div>
            
            </div>
            </Fade>
    </div>
  )
}

export default Blockchain