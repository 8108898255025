import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import "./counter.css";
const Counter = ({end,text,decimals,before,after}) => {
  return (
    <div className='counter'>
        <div className='counter_header'>
            {before}<CountUp end={end} decimals={decimals} redraw={true}>
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                </VisibilitySensor>
            )}
        </CountUp>{after}
    </div>
    <p>{text}</p>

    </div>
  )
}

export default Counter