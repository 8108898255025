import React from 'react';
import './usecase.css';
import Card from '../card/Card.jsx';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  { Fade } from 'react-awesome-reveal';

const UseCase = () => {

  return (
    <div id = "usecases" className='usecase'>
      <Fade>
            <div className='usecase_header'>
                <h2>NFT use cases</h2>
                <div></div>
                <p>
                We can combine our NFT expertise with numerous industries. We offer NFT development services in multiple domains. Let’s create the future together and build something outstanding!            </p>
            </div>
            
            <div className='usecase_container'>
                <div className='cards'>
                    <Card />
                </div>
            </div>
        </Fade>
    </div>
  )
}

export default UseCase