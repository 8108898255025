import React from 'react'
import './footer.css';
import  { Fade } from 'react-awesome-reveal';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='footer'>
    <Fade>
        <div className='footer_contact'>
          <h3>AUSTRIA</h3>
          <p>Auweg 4</p>
          <p>4931 Mettmach</p>
        </div>
        <div className='footer_menue'>
        <a href='/#nft'><div className='footer_menue_tab'>NFT</div></a>
        <a href='/#services'><div className='footer_menue_tab'>Services</div></a>
        <a href='/#usecases'><div className='footer_menue_tab'>Use Cases</div></a>
        <Link to='/aboutus'> <div className='footer_menue_tab'>About Us</div></Link>
        <a href='/#FAQ'> <div className='footer_menue_tab'>FAQ</div></a>
        <Link to="/privatepolicy"><div className='footer_menue_tab'>Private Policy</div></Link>
        <Link to="/contactus"><button>Get in Touch</button></Link>

        </div>
        </Fade>
    </div>
  )
}

export default Footer