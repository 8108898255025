import React, {useState} from 'react';
import { Link } from "react-router-dom";
import logo from '../../assets/logo.svg';
import{RiMenu3Line,RiCloseLine} from 'react-icons/ri';
import './navbar.css';
const Menu = () => (
  <>
  <p><a href='/#nft'>NFT</a></p>
  <p><a href="/#services">Services</a></p>
  <p><a href="/#usecases">Use Cases</a></p>
  <p><Link to="/aboutus">About us</Link></p>
  <p><a href="/#FAQ">FAQ</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu,setToggleMenu] = useState(false);
  return (
    <div className='navbar'>
      <div className='navbar_logo'>
        <a href="/"><img src={logo} alt="w3minds"/></a>
      </div>
      <div className='navbar_container'>
        <Menu/>
      </div>
      <div className="navbar-sign">
      <Link to="/contactus"><button type="button">Get in touch</button></Link>
      </div>
      <div className="navbar-menu">
        {toggleMenu 
          ? <RiCloseLine color="#808080" size={27} onClick={() => setToggleMenu(false)}/>
          : <RiMenu3Line color="#808080" size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div calssname ="navbar-menu_container-links">
              <Menu/>
            </div>
            <div className="navbar-menu_container-links-sign">
            <Link to="/contactus"><button type="button">Get in touch</button></Link>
              </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar