import React, {useState} from 'react';
import "./faqcontent.css";
import FAQ from '../FAQ/FAQ.jsx';
import  { Fade } from 'react-awesome-reveal';

const FAQContent = () => {
  const [faqs, setfaqs] = useState([
    {
      question: 'What are NFTs?',
      answer: 'NFTs i.e. Non-Fungible Tokens are crypto-enabled tokens that offer a unique identity for provable ownership over any physical or digital asset. NFTs include all digital artworks comprising music, video, songs, paintings, and social media tweets.',
      open: true
    },
    {
      question: 'How do NFTs work?',
      answer: 'NFT token offers ownership of the item similar to cryptographed coins. It ensures the attested ownership over the digital asset. The difference is that NFTs are non-interchangeable and cannot be broken into parts.',
      open: false
    },
    {
      question: 'Why are the NFTs so popular and why are people buying them?',
      answer: 'There are many different reasons why NFTs are so hyped. One of the main reasons for this is the versatility of NFTs. The NFT market is booming and will continue to expand rapidly in the future.',
      open: false
    },
    {
      question: 'What token standards are used for NFTs?',
      answer: 'The major NFT standards are ERC-721 and ERC-1155 – the first being the leader for advanced validation functionality, the second supporting both fungible and non-fungible tokens.',
      open: false
    },
    {
      question: 'Can I create my own NFT collection and sell it?',
      answer: "Yes, you can create your NFT by choosing the suitable NFT platform and connecting the payment wallet. w3minds Infotech can help you create your own NFT that you can sell on multiple platforms.",
      open: false
    },
    {
      question: 'Where can NFTs be used?',
      answer: 'Today’s top contributing fields are NFT art maketplaced, logistics and supply chain, digital identity, decentralized finance, real estate, gaming, and fashion, but there are no limits for the use of NFTs.',
      open: false
    },
    {
      question: 'What is the best blockchain choice for an NFT project?',
      answer: 'The choice will largely depend on your functional and non-functional requirements, along with your business purpose and other specifics.',
      open: false
    },
    {
      question: 'Can you help me launch my NFT collection?',
      answer: 'Don’t hesitate to get in touch with us. w3minds got experts in all possible fields of NFTs.',
      open: false
    }
  ]);
  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }
  return (
    <div id="FAQ" className='faqcontent'>
      <Fade>
            <div className='faqcontent_header'>
                <h2>Frequently asked questions</h2>
                <div></div>
            </div>
            <div className='faqs2'>
              {faqs.map((faq, i) => (
              <FAQ faq={faq} key={i} index={i} toggleFAQ={toggleFAQ} />
            ))}
            </div>
            </Fade>
    </div>
  )
}

export default FAQContent