import React from 'react';
import './aboutus.css';
import nft_aboutus from '../../assets/nft_aboutus.jpg';
import tobiasw from '../../assets/tobiasw.png';
import michaelw from '../../assets/michaelw.png';
import Contact from '../contact/Contact';
import  { Fade } from 'react-awesome-reveal';
const Aboutus = () => {
  return (
    <div>
    <div className='aboutus'>
      <Fade>
        <div className='aboutus_header'>
            <h2>About w3minds</h2>
            <div className='line'></div>
                <h3>w3minds has what it takes to hold on to its stripes of a trusted tech provider, delivering internationally. Every day we do our best to make clients happy all over the world. We’re after laying the groundwork for full-blown and uncompromisingly legit digital economies on a global scale.</h3>
        </div>
        <div className='aboutus_image'>
          <img src={nft_aboutus} alt='nft_tech' />
        </div>
        <div>
        <div className='aboutus_header'>
        <h3>We are a premium software development company that delivers truly outstanding solutions to our clients. Anything coming out from w3minds is of the highest quality and second to none. We have a handpicked selection of the finest developers, who always believe any task can be done more smartly, and any existing system can be challenged, and ultimately, improved.</h3>
        </div>
        </div>
        </Fade>
    </div>


    <div className='team'>
    <h2>Meet our leadership team</h2>
    <Fade>
    <div className='team_container'>
        <p>Getting introduced the ones who are setting things right within any tech question. </p>
        <div className="team_founders">
          <Fade direction='left'>
            <div className="team_founder">
              <img src={tobiasw} alt="Tobias" />
              <h3>Tobias W.</h3>
              <span>CEO & Founder</span>
            </div>
            </Fade>
            <Fade direction='right'>
            <div className="team_founder">
              <img src={michaelw} alt="Michael" />
              <h3>Michael W.</h3>
              <span>CEO & Founder</span>
            </div>
            </Fade>
        </div>
    </div>
    </Fade>
    </div>
    <Contact />
    </div>

  )
}

export default Aboutus