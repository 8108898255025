import React from 'react';
const NFTFact = ({headline,text}) => {
  return (
    <div className='nft_fact'>
        <h3>{headline}</h3>
        <p>{text}</p>

    </div>
  )
}

export default NFTFact