import './App.css';
import {Navbar, Header, Nft,Services,Aboutus,Contact,Footer, UseCase, FAQContent, Blockchain,GetInTouch,Policy} from './components';

import { BrowserRouter as Router,Route,Switch} from 'react-router-dom';
function App() {
  return (
    <div className='App'>
        <Router>
          <Navbar />
          
          <div className='content'>
              <Switch>
                <Route exact path="/">
                  <Header></Header>
                  <Nft></Nft>
                  <Services></Services>
                  <UseCase></UseCase>
                  <Blockchain></Blockchain>
                  <FAQContent></FAQContent>
                  <Contact></Contact>
                </Route>
                <Route exact path="/aboutus">
                  <Aboutus></Aboutus>
                </Route>
                <Route exact path="/contactus">
                  <GetInTouch></GetInTouch>
                </Route>
                <Route exact path="/privatepolicy">
                  <Policy></Policy>
                </Route>
              </Switch>
          </div>
          <Footer></Footer>
        </Router>
      </div>
  );
}

export default App;
