import React from 'react'
import Contact from '../contact/Contact';
import contact_w3minds from '../../assets/contact_w3minds.jpg';
import './getintouch.css'
import  { Fade } from 'react-awesome-reveal';

const GetInTouch = () => {
  return (
    <div className='getintouch'>
        <div className='getintouch_header'>
          <Fade direction='left'>
            <div className='getintouch_text'>
                <h2>Get in Touch</h2> 
                <div></div>
                <h3>Let's discuss how we can collaborate or schedule a meeting</h3>
                <p>Do they have questions about NFT, blockchain, metaverse, or anything else? Reach out to us for plentiful insights on digital innovation and developing low-risk solutions.</p>
                <p>Whether you are looking for a software project estimation or a technical consultation on your idea, you're in the right place.</p>
                <a href='#contact'><button type ="button">Contact us</button></a>
            </div>
            </Fade>
            <Fade direction='right'>
            <div className='getintouch_image'>
                <img src={contact_w3minds} alt="contact_w3minds" />
            </div>
            </Fade>
      </div>
        <Contact/>
        </div>
  )
}

export default GetInTouch