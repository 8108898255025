import React, {useState,useRef} from 'react'
import emailjs from 'emailjs-com';
import  { Fade } from 'react-awesome-reveal';
import { Link } from "react-router-dom";
import './contact.css'
const Contact = () => {
  const form = useRef();
  const [focused, setFocused] = useState(false);
  const [values, setValues] = useState({
    name: "",
    company: "",
    email: "",
    phonenumber: "",
    message: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ybsdg0y', 'template_o5cf3lh', form.current, '6RLGV3GNsEAV-4ZIh')
      .then((result) => {
          console.log(result.text);
          form.current.reset();
          form.current.style.display="none";
          document.getElementById("submitted").style.display="flex";
      }, (error) => {

          console.log("ERROR: " +error.text);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleFocus = (e) => {
    setFocused(true);
  };
  return (
    <Fade>
    <div id='contact' className='contact'>
      <form ref={form} onSubmit={handleSubmit}>
      <div className='contact_header'>
          <h2>Contact us</h2>
          <div></div>
          <p>
          Leave us a note, and we'll get back to you within 24 hours.</p>
      </div>
      <div className='form'>
        <div className='row'>
        <div className="formInput">
          <label>Name</label>
          <input
            type='text'
            name='name'
            pattern='^[A-Za-z ]{3,30}$'
            required = {true}
            onChange={onChange}
            onBlur={handleFocus}
            placeholder=""
            focused={focused.toString()}
          />
          <span>Name should be 3-30 characters and shouldn't include any special character!</span>
        </div>
        <div className="formInput">
          <label>Company(optional)</label>
          <input
            type='text'
            name='company'
            pattern='^[A-Za-z0-9 ]{3,16}$'
            onChange={onChange}
            onBlur={handleFocus}
            placeholder=""
            focused={focused.toString()}
          />
          <span>Name should be 3-16 characters and shouldn't include any special character!</span>
        </div>
        </div>
        <div className='row'>
        <div className="formInput">
          <label>Email</label>
          <input
            type='email'
            name='email'
            required = {true}
            onChange={onChange}
            onBlur={handleFocus}
            placeholder="e.g. name@email.com"
            focused={focused.toString()}
          />
          <span>It should be a valid email address!</span>
        </div>
        <div className="formInput">
          <label>Phone number (optional)</label>
          <input
            type='text'
            name='phonenumber'
            onChange={onChange}
            onBlur={handleFocus}
            placeholder="e.g. +43 111 123 4567"
            focused={focused.toString()}
          />
          <span></span>
        </div>
        </div>
        <div className="formInput">
          <label>Message</label>
          <textarea
              type='text'
              className='message'
              name='message'
              required = {true}
              onChange={onChange}
              onBlur={handleFocus}
              pattern='^[A-Za-z0-9 .,!-?#+*_]{25,500}$'
              placeholder="Tell us about your idea .."
              focused={focused.toString()}
          ></textarea>
          <span>asd</span>
        </div>
        
        <button>Send message</button>
      </div>
        
      </form>
      
      <div id="submitted" className="submitted">
          <h2>Thank you for contacting w3minds!</h2>
          <p>Your message has been successfully received!</p>
      </div>
      <div className='contact_privacy'>
    <p>By clicking the 'Send Message' button, you agree that w3minds will process your personal information in accordance with our <Link to="/privatepolicy">Privacy Policy</Link> and send you marketing materials. You can unsubscribe at any time.</p>
    </div>
  </div>
  </Fade>
  )
}

export default Contact