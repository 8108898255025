import React, { useState } from 'react';
import './services.css';
import FAQ from '../FAQ/FAQ.jsx';
import nftservices from '../../assets/nft_services.jpg';
import  { Fade } from 'react-awesome-reveal';

const Services = () => {
  const [faqs, setfaqs] = useState([
    {
      question: 'NFT consulting',
      answer: 'The NFT business is full of pitfalls and alone the way to the goal can be very rocky and difficult. With our experience we help in the entire technical planning and implementation to avoid errors and thus follow-up costs.',
      open: true
    },
    {
      question: 'NFT development',
      answer: 'No matter what direction your NFT project or idea is taking, we are ready to assist you. We help you reach far beyond your business goals!',
      open: false
    },
    {
      question: 'NFT smart contact development',
      answer: 'The core processing NFTs are developed for storing the information. Assuring the immutability and transparency of the information, NFT smart contract developed by w3minds is best for controlling the digital asset. Join the wagon and step forward with the successful development.',
      open: false
    },
    {
      question: 'NFT website development',
      answer: 'A professional appearance on the Internet is very crucial. We design your desired NFT website, according to your own ideas. With the help of our talented web3 specialists all necessary integrations are implemented.',
      open: false
    },
    {
      question: 'NFT Art creation',
      answer: "No art yet? No problem! We have many talented artists in the team, who will respond exactly to the client's request and create the desired art.",
      open: false
    },
    {
      question: 'NFT Maintenance & Support',
      answer: 'With a wide range of services, w3minds provides continuous support and maintenance for client projects. We are best at project procurement and hassle-free upgrades to the latest versions. Alongside, smooth migration is our forte for your first digital attempt.',
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }
  return (
    <div className='services' id='services'>
      <Fade>
      <div className='services_header'>
        <h2>w3minds as an all-in-one shop for NFT services</h2>
        <div></div>
        <p>
        Being a top NFT token development company, our non-fungible token development services facilitate the digital assets to be tokenized for collection. We work with the latest technologies and fulfill every wish of our clients, as transparency and professionalism are most important for us. w3minds helps you to launch your NFT for all your business needs. The success of our clients is our success!
        </p>
      </div>
      </Fade>
      <div className='services_container'>
      <Fade direction="left">
        <div className='services_container_image'>
          <img src={nftservices} alt="nft_services" />
        </div>
        </Fade>
        <Fade direction="right">
        <div className='services_container_list'>
        <div className="faqs">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} key={i} index={i} toggleFAQ={toggleFAQ} />
          ))}
        </div>
        </div>
        </Fade>
      </div>
      <Fade>
      <div className='service_board'>
        <h3>Discuss your project with our specialists</h3>
        <p>Looking for the perfect solution? Do you have questions about our services? 
          w3minds looks forward to shedding light on the NFT phenomenon.
        </p>
        <a href='#contact'><button type ="button">Discuss your project </button></a>
      </div>
      </Fade>
    </div>
  )
}

export default Services