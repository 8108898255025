import React from 'react';
import Counter from '../counter/Counter.jsx';
import NFTFact from '../nftfact/NFTFact.jsx';
import  { Fade } from 'react-awesome-reveal';
import './nft.css';
const Nft = () => {
  return (
    <div  id='nft' className='nft'>
      <Fade>
      <div className='nft_header'>
        <h2>The impressive world of NFTs</h2>
        <div></div>
        <p>
        The NFT market is probably the most popular and emerging market at the moment. The NFT space is a buzzing new industry offering you a new dynamic way to engage with your audience. NFTs and the Metaverse are important because they offer businesses and brands a new way to create and interact with digital assets. The following values are simply impressive:
        </p>
      </div>
      </Fade>
      <Fade right>
      <div className="nft_counter">   
        <Counter end="41" text="Total 2021 NFT market cap" decimals="0" before="~$" after="B" />
        <Counter end="23" text="Annual NFT trading volume 2021" decimals="0" before="~$" after="B"/>
        <Counter end="6.6" text="monthly trading volume record in April 2022" decimals="1" before="~$" after="B"/>
        <Counter end="6.6" text="NFTs are traded on the blockchain network every week" decimals="0" before="~$" after="M"/>
        <Counter end="700" text="was the increase in NFT trades between Q2 and Q3 2021" decimals="0" before="+" after="%"/>
        <Counter end="532" text="was the value of the most expensive NFT ever" decimals="0" before="$" after="M"/>
        
      </div>
      </Fade>
      <Fade direction="right" >
      <div className="nft_facts"> 
        <NFTFact headline="Uniqueness" text = "People love unique things - create your own distinctive NFTs and add value - distinctive, unique and incomparable"/>
        <NFTFact headline="Market" text = "With NFTs there are no limits - new markets open, old markets offer new opportunities - enter the deep world of NFTs with limitless possibilities"/>
        <NFTFact headline="Security" text = "NFT blockchain smart contracts logic guarantees 100% security - especially in times of cybercrime, this technology guarantees tamper-proof data storage, P2P verification and transaction tracking."/>
        <NFTFact headline="Financial opportunities" text = "Use NFTs also in non-traditional sectors - any NFT marketplace contributor can redefine the way they make profit and share property or funds."/>
      </div>
      </Fade>
    </div> 
  )
}

export default Nft