import React from "react";
import Slider from "react-slick";

import LeftArrow from "../../assets/arrowside.svg"
import nft_gaming from '../../assets/nft_gaming.jpg';
import nft_cryptocollectible from '../../assets/nft_cryptocollectible.jpg';
import nft_education from '../../assets/nft_education.jpg';
import nft_softwarelicense from '../../assets/nft_softwarelicense.jpg';
import nft_fashion from '../../assets/nft_fashion.jpg';
import nft_media from '../../assets/nft_media.jpg';
import nft_socialmedia from '../../assets/nft_socialmedia.jpg';
import nft_sports from '../../assets/nft_sports.jpg';
import nft_ticketing from '../../assets/nft_ticketing.jpg';
import metaverse from '../../assets/metaverse.jpg';
import nft_digitalarts from '../../assets/nft_digitalarts.jpg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: "block", transform: "rotate(-90deg)",right:"-30px", height: "32px", width:"32px",filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))", backgroundColor: "rgba(0, 0, 0, 0.6)"  ,padding: "2.5px" }}
      onClick={onClick}
      src={LeftArrow}
      alt="NFT Usecases Left"
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style, display: "block", transform: "rotate(90deg)", height: "32px", width:"32px",filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))", backgroundColor: "rgba(0, 0, 0, 0.6)"  ,padding: "2.5px" }}
      onClick={onClick}
      src={LeftArrow}
      alt="NFT Usecases Right"
    />
  );
}


export default function Card() {

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      prevArrow: <SamplePrevArrow />,
      nextArrow: <SampleNextArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]

    };
    return (
     <div className="card__container"> 
 <Slider {...settings} className="card__container--inner">
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_digitalarts} alt="Digital arts NFTs" />
                  <div className="card_content">             
                    <h2>Digital arts NFTs</h2>
                    <p>Like many celebrities today, artists can trade their artwork on the NFT platform without worried about copyright issues and content theft.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_gaming} alt="Gaming NFTs" />
                  <div className="card_content">             
                    <h2>Gaming NFTs</h2>
                    <p>NFT marketplace development is leveraging the games with in-game avatars, skins, and other collectibles. Enjoy the rich gaming with proof of authenticity and the possibility of a play to earn model.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_socialmedia} alt="Social Media NFTs" />
                  <div className="card_content">             
                    <h2>Social Media NFTs</h2>
                    <p>NFTs don't stop at social media. NFTs for the audience and NFTs for social media brands set new standards and inspire the masses.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_sports} alt="Sports NFTs" />
                  <div className="card_content">             
                    <h2>Sports NFTs</h2>
                    <p>Sports NFTs are booming. Many well-known sports clubs have already jumped on the NFT bandwagon and have their own collections.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={metaverse} alt="Metaverse NFTs" />
                  <div className="card_content">             
                    <h2>Metaverse NFTs</h2>
                    <p>Virtual investment and properties have become the popular talk of the industry. Still, in the development stage, the Metaverse is building a stable ground for NFT tokens.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_ticketing} alt="Ticketing NFTs" />
                  <div className="card_content">             
                    <h2>Ticketing NFTs</h2>
                    <p>Access to events, parties, festivals and many more can be obtained with the help of NFTs. Numerous new opportunities open up for the owners of your NFTs.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_education} alt="Education" />
                  <div className="card_content">             
                    <h2>Education</h2>
                    <p>The NFT scene is already an integral part of continuing education as well. NFT Holders get access to your knowledge and thus access to infinite possibilities.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_media} alt="Media & Entertainment" />
                  <div className="card_content">             
                    <h2>Media & Entertainment</h2>
                    <p>Earn the skyrocketing value for the NFT on videos or Music. The Creator economy has given huge rise to more revenue-generating opportunities and plentiful benefits.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_fashion} alt="Fashion NFTs" />
                  <div className="card_content">             
                    <h2>Fashion NFTs</h2>
                    <p>You have your own fashion brand and want to hype your customers? Join the trend and hit an NFT collection with digitized passes to limited edition fashion.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_cryptocollectible} alt="Crypto Collectibles" />
                  <div className="card_content">             
                    <h2>Crypto Collectibles</h2>
                    <p>Crypto collectibles are unique items that can be sold or bought on the blockchain. The blockchain crypto-collectibles are easy to verify the ownership and record the transaction.</p>
                  </div> 
                </div>
            </div>
            <div className="card__container--inner--card">
                <div className="card">
                  <img src={nft_softwarelicense} alt="Software License Management" />
                  <div className="card_content">             
                    <h2>Software License Management</h2>
                    <p>Ensure the attestation of ownership over the software licenses. Mint NFT for your software distribution and represent each license with an NFT for an improved level of ownership.</p>
                  </div> 
                </div>
            </div>
        </Slider>
     </div>
       
    );
  }